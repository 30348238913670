<script setup>
import config from '@/pages/brands/configs/brands.config'

const { cols, tableActions, filters, actionList, searchList, tableId } = config.config()

import { ModalModel } from '@/entities/Modal'
import { BrandsModel } from '@/entities/Brands'

import { DataTable } from '@/features/Datatable'
import { BaseButton } from '@/shared/ui/Buttons'
import { PaginationBox, FiltersList, TableActions } from '@/features/Datatable'
import { PageTitle } from '@/shared/ui/Page'
import { usePageTitle } from '@/shared/lib/use/usePageTitle'
import {useRouter} from "vue-router"

const router = useRouter()

const modalStore = ModalModel.useModalStore()
const store = BrandsModel.useBrandsStore()
const title = usePageTitle('Brands')

const edit = (data) => {
    store.setBrand(data)
    router.push({ name: 'brandPage', params: { id: data.id.data } })
}

const update = (action, item, row) => {
    item.selectIsOpen = false
    item.selectType = 0
    switch (action) {
        case 'edit':
            edit(row)
            break
        default:
            break
    }
}

const add = () => {
    modalStore.setModal('createBrand')
}
</script>

<template>
    <div class="brands-page container page">
        <PageTitle :title="'Brands'" :icon="'page-icons/brands.svg'">
            <template #custom-action>
                <BaseButton :icon="'plus.svg'" :width="40" :height="40" :border-radius="'4px'" @click="add()" />
            </template>
        </PageTitle>
        <DataTable
            :fields="cols"
            :is-draggable="true"
            :path="'brands/dt'"
            :table-id="tableId"
            :option-list="tableActions"
            :action-list="actionList"
            :order="'numericId'"
            @open="edit"
            @action="update"
        >
            <template #top-left>
                <FiltersList :filters="filters" :search-list="searchList" :button-text="'Search'" />
            </template>
            <template #center-right>
                <TableActions :search-list="searchList" />
            </template>
            <template #bottom-left>
                <PaginationBox />
            </template>
        </DataTable>
    </div>
</template>

<style scoped></style>
