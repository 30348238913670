<script setup>
/**
 * SearchInput
 * ------------------------------
 * Description:
 * A Vue component representing a search input field with options for filtering.
 *
 * Usage:
 * <SearchInput
 *    :searchList="[
 *        { value: 'Email', key: 'globalFilter' },
 *        { value: 'Name', key: 'globalFilter' },
 *        { value: 'Date', key: 'globalFilter' }
 *    ]"
 * />
 *
 * Props:
 * - searchList: An array of objects representing search filter options. Each object should have a 'value' and a 'key'.
 * - width: Width search input.
 */

import { ref, defineEmits, defineProps, onMounted } from 'vue'
import { BaseImage } from '@/shared/ui/Images'
import { BaseInput } from '@/shared/ui/Inputs'

const props = defineProps({
    searchList: {
        type: Array,
        default: () => [
            {
                value: 'Email',
                key: 'globalFilter'
            }
        ]
    },
    width: {
        type: Number,
        default: 230
    },
    tableId: {
        type: String
    }
})

import { DatatableModel } from '@/entities/Datatable'
import { SourcesModel } from '@/entities/Sources'

onMounted(() => {
    selectedSearchType.value = props.searchList[0]
})

const datatableStore = DatatableModel.useDatatableStore()
const store = SourcesModel.useSourcesStore()

const emits = defineEmits(['search'])
const search = ref('')
const selectIsOpen = ref(false)
const visibleSearchBox = ref(false)
const selectedSearchType = ref({})

const clear = () => {
    search.value = ''

    // todo temp, remove in future
    if (props.tableId === 'sources-table') {
        store.clearGlobalFilter()
        return
    }
    datatableStore.clearGlobalFilter()
}

const startSearch = () => {
    emits('search', {
        key: selectedSearchType.value.key || props.searchList[0].key,
        value: search.value
    })
}

const setSearchType = (data) => {
    clear()
    setTimeout(() => {
        selectedSearchType.value = data
        visibleSearchBox.value = false
    }, 200)
}

const openSelectBox = () => {
    visibleSearchBox.value = !visibleSearchBox.value
}
</script>

<template>
    <div
        class="search-input"
        :style="{ width: width + 'px' }"
        :class="{ open: selectIsOpen }"
        @keypress.enter="startSearch"
    >
        <!--        <div v-if="Object.keys(searchList).length > 1" class="image-box-search" @click="openSelectBox()">-->
        <!--            <BaseImage :path="'arrow.svg'" :width="12" :height="6" class="arrow" :class="{open: visibleSearchBox}"/>-->
        <!--        </div>-->
        <BaseInput
            v-model="search"
            :padding="{ left: '0px', top: '10px', bottom: '10px' }"
            :placeholder="`Search by ${selectedSearchType.value}...`"
        />
        <BaseImage
            :path="'close-gray.svg'"
            :width="11"
            :height="11"
            class="clear"
            :class="{ hide: !search }"
            @click.stop="clear()"
        />
        <!--        <span class="material-icons clear" :class="{hide: !search}" @click.stop="clear()">close</span>-->
        <div class="image-box" @click="startSearch">
            <BaseImage :path="'search.svg'" class="search-icon" :width="20" :height="20" />
        </div>
        <!--        <ul class="search-box" v-if="visibleSearchBox">-->
        <!--            <template v-for="item in searchList" :key="item.key">-->
        <!--                <li class="item" @click="setSearchType(item)">{{ item.value }}</li>-->
        <!--            </template>-->
        <!--        </ul>-->
    </div>
</template>

<style scoped>
.search-input {
    background: var(--select-bg);
    border-radius: var(--general-border-radius);
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    position: relative;
}

.arrow {
    margin-right: 10px;
    transition: transform 0.3s ease;
}

.arrow.open {
    transform: rotate(180deg);
}

.image-box-search {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px 0;
}

.search-box {
    position: absolute;
    top: 50px;
    left: 0;
    background: var(--select-bg);
    width: 100%;
    padding: 10px;
    border-radius: var(--general-border-radius);
}

.search-box .item {
    margin-bottom: 10px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.search-box .item:hover {
    color: var(--secondary-accent);
}

.search-box .item:last-child {
    margin-bottom: 0;
}

.arrow {
    cursor: pointer;
}

.search-input span {
    cursor: pointer;
    color: var(--primary-gray);
    user-select: none;
}

.search-input .clear {
    margin: 0 10px;
    cursor: pointer;
}

.search-input .clear.hide {
    opacity: 0;
    visibility: hidden;
}

.search-input .image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    color: var(--primary-white);
    border-radius: 0;
}

.search {
    padding: 10px 0 10px 20px;
    background: transparent;
    outline: none;
    border: 0;
    color: var(--primary-white);
}

.image-box {
    cursor: pointer;
}
</style>
