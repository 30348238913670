import { createApp } from 'vue'
import { router, store } from './providers'

import * as Sentry from "@sentry/vue";

import App from './App.vue'

export const application = createApp(App)

Sentry.init({
    application,
    dsn: "https://58ae9f3abc73f409951dd45f4c98b4f5@o4504802128101376.ingest.us.sentry.io/4508097259307008",
    environment: process.env.NODE_ENV,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

application.use(router)
application.use(store)
