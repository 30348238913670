<script setup>
import { computed, ref, defineEmits } from 'vue'
import { useAppStore } from '@/app/providers'
import { SessionModel } from '@/entities/Session'

import {BaseInput, BaseSelectBox, BaseTextarea, BaseToggle} from '@/shared/ui/Inputs'
import { BaseButton } from '@/shared/ui/Buttons'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import { BaseModal } from '@/shared/ui/Modal'
import { BaseBorderPanel } from '@/shared/ui/Panels'
import {isValidDomain} from "@/shared/lib/utils/validation"
import {BaseImage} from "@/shared/ui/Images";
import ParamsList from "@/features/Brands/ParamsList/ui/ParamsList.vue";

const emits = defineEmits(['close'])

const appStore = useAppStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const sessionStore = SessionModel.useSessionStore()

const requestIsPending = computed(() => appStore.requestIsPending)
const connectors = computed(() => SessionModel.getConnectors(sessionStore))

const note = ref('')
const brand = ref('')
const name = ref('')
const emailValidation = ref(0)
const privacy = ref(1)
const apiConnector = ref('')
const apiToken = ref('')
const apiUrl = ref('')
const apiUrlParam1 = ref('')
const isShowParamsList = ref(false)
const paramsList = ref( [
    {
        key: '',
        value: '',
        state: false
    }
])


const create = () => {
    if (!brand.value) {
        generalNotificationsStore.showError({
            message: 'Enter Brand'
        })
        return
    }

    if (!name.value) {
        generalNotificationsStore.showError({
            message: 'Enter Brand Name'
        })
        return
    }

    if (!isValidDomain(apiUrl.value)) {
        generalNotificationsStore.showError({
            message: 'Not valid Api URl'
        })
        return
    }

    let tempParamsList = {}

    for (const param in paramsList.value) {
        tempParamsList[paramsList.value[param].key] = paramsList.value[param].value
    }

    appStore.create(
        {
            note: note.value,
            emailValidation: emailValidation.value,
            privacy: privacy.value,
            brand: brand.value,
            name: name.value,
            apiToken: apiToken.value,
            apiConnector: apiConnector.value,
            apiUrl: apiUrl.value,
            apiUrlParam1: apiUrlParam1.value,
            apiUrlParams: tempParamsList
        },
        'brands'
    ).then(() => {
        appStore.getList('brands')
    })
}

const cancel = () => {
    close()
}

const close = () => {
    emits('close')
}

const changePrivacy = (option) => {
    privacy.value = option
}

const saveParamsList = (params) => {
    paramsList.value = params
}

const showParamsList = () => {
    isShowParamsList.value = !isShowParamsList.value
}
</script>

<template>
    <BaseModal >
        <template #title>
            <h4 class="typo-heading-h4">Add new brand</h4>
        </template>
        <template #content>
            <div class="create-brand">
                <BaseBorderPanel>
                    <template #title>
                        <h5 class="typo-heading-h5">Add parameters for API brand</h5>
                    </template>
                    <template #content>
                        <div class="api">
                            <BaseInput v-model="brand" :placeholder="'Enter Brand'" :label="'Brand Parameter'" />
                            <BaseInput
                                v-model="name"
                                :placeholder="'Enter Brand Name'"
                                :label="'Brand Name Parameter'"
                            />
                            <BaseInput v-model="apiToken" :placeholder="'Enter API Token'" :label="'API Token'" />
                            <BaseInput v-model="apiUrl" :placeholder="'Enter API Url'" :label="'Brand API Urls'" />
                            <BaseInput v-model="apiUrlParam1" :placeholder="'Enter Api Url Param1'">
                                <template #label>
                                    <label>Api Url Param1</label>
                                </template>
                            </BaseInput>
                            <BaseSelectBox
                                v-model="apiConnector"
                                :options="
                                    Object.entries(connectors).map(([key, value]) => ({
                                        value: value,
                                        title: value
                                    }))
                                "
                                :placeholder="connectors[apiConnector] || 'Select Connector'"
                            >
                                <template #label>
                                    <label>Connector</label>
                                </template>
                            </BaseSelectBox>
                        </div>
                    </template>
                </BaseBorderPanel>

                <BaseBorderPanel>
                    <template #title>
                        <h5 class="typo-heading-h5 pointer" @click="showParamsList">
                            Additional parameters
                            <BaseImage :height="5" :width="10" :path="'arrow.svg'"/>
                        </h5>
                    </template>
                    <template v-if="isShowParamsList" #content>
                        <ParamsList
                            :params="paramsList"
                            @save="saveParamsList"
                        />
                    </template>
                </BaseBorderPanel>

                <BaseBorderPanel>
                    <template #title>
                        <h5 class="typo-heading-h5">Privacy</h5>
                    </template>
                    <template #content>
                        <div class="info-box">
                            <div class="info">
                                <p>What is the campaign privacy?</p>
                                <p>Public campaigns will include all affiliates automatically.</p>
                                <p>Private campaigns will only include manually added affiliates</p>
                            </div>
                            <div class="buttons">
                                <BaseButton
                                    :text="'Public'"
                                    :design="`${!privacy ? 'outline' : 'fill'}`"
                                    :width="100"
                                    :height="40"
                                    @click="changePrivacy(1)"
                                />
                                <BaseButton
                                    :text="'Private'"
                                    :design="`${privacy ? 'outline' : 'fill'}`"
                                    :width="100"
                                    :height="40"
                                    @click="changePrivacy(0)"
                                />
                            </div>
                        </div>
                    </template>
                </BaseBorderPanel>

                <BaseBorderPanel>
                    <template #title>
                        <h5 class="typo-heading-h5">Check Email Validation</h5>
                    </template>
                    <template #content>
                        <div class="email-validation">
                            <span class="typo-heading-small"
                                >When active, invalid emails result in lead failure and appear as unassigned.</span
                            >
                            <div class="switch-content">
                                <span class="typo-heading-small">Check Email Validation</span>
                                <BaseToggle :value="emailValidation" @change="(value) => (emailValidation = value)">
                                    <template #state>
                                        {{ emailValidation ? 'On' : 'Off' }}
                                    </template>
                                </BaseToggle>
                            </div>
                        </div>
                    </template>
                </BaseBorderPanel>

                <BaseBorderPanel>
                    <template #content>
                        <BaseTextarea
                            v-model="note"
                            :height="90"
                            :label="'Note'"
                            :placeholder="'Enter the description'"
                        />
                    </template>
                </BaseBorderPanel>
            </div>
        </template>
        <template #action-buttons>
            <BaseButton :text="'Cancel'" :width="100" :design="'outline'" @click="cancel()" />
            <BaseButton :text="'Create Campaign'" :width="155" :load="requestIsPending" @click="create()" />
        </template>
    </BaseModal>
</template>

<style scoped>
.modal-box {
    padding: 130px 24px 0;
}

.create-brand {
    width: 1000px;
    background-color: var(--background);
    border-radius: var(--general-border-radius);
    padding-top: 16px;
}

.api {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}

.info-box {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 70px;
    place-items: center center;
}

p {
    margin-bottom: 8px;
    font-size: 12px;
}

p:last-child {
    margin-bottom: 0;
}

.buttons button:first-child {
    margin-right: 10px;
}

.switch-content {
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap: 20px;
}

.button-box.outline {
    color: var(--white-086);
}

.switch-box {
    gap: 6px;
}

.input-box {
    display: flex;
    flex-direction: column;
}

.open img {
    transform: rotate(180deg);
}

@media (max-width: 1100px) {
    .api {
        grid-template-columns: 1fr;
    }

    .create-brand {
        width: auto;
    }

    .info-box {
        grid-template-columns: 1fr;
        place-items: flex-start;
        gap: 24px;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .switch-content {
        margin: 16px 0;
    }
}
</style>
